const actions = {
  submit: 'Confirm',
  send: 'Send',
  pay: 'Pay',
  edit: 'Edit',
  open: 'Open',
  top: 'Boost',
  publish: 'Publish',
  duplicate: 'Duplicate',
  save: 'Save',
  saveAndClose: 'Save and close',
  loadMore: 'Load more',
  hide: 'Hide',
  show: 'Show',
  select: 'Select',
  selectOneJob: 'Select 1 offer',
  conclude: 'Close',
  pause: 'Pause',
  login: 'Sign in',
  logout: 'Sign out',
  add: 'Add',
  close: 'Close',
  cancel: 'Cancel',
  showMore: 'Show more',
  signUp: 'Sign Up',
  finishLater: 'Finish later',
  delete: 'Delete',
}

const error = {
  invalid: 'Invalid',
  wrongFormat: 'Invalid format',
  selectAtLeastOneOption: 'Select at least 1 option',
  invalidURL: 'URL not valid',
  invalidData: 'Entry not valid',
  wrongEmailOrPassword: 'Oops! Email or password you entered is not correct',
  emailExists: 'The entered e-mail is already registered.',
  invalidFormat: 'Format not valid',
  invalidEmail: 'Email not valid',
  invalidPhone: 'Phone number not valid',
  errorWhileLogingIn: 'There was an error during login',
  somethingWenWrongTrySendFormAgain:
    'Oops! Something went wrong. Please try sending the form again.',
  sendingError: 'Oops! There was an error while sending.',
  errorDuringSaving: 'There was an error while saving your changes',
  errorDuringProcessingTryAgain: 'Processing error. Please try again.',
  errorDuringLoadingTryAgain: 'Loading error. Please try loading the page again.',
  unknownErrorTrySendAgain: 'Unknown error. Please try sending again.',
  errorCheckAllRequiredField: 'Error! Please check all required fields.',
  fillAllRequiredFormFields: 'Oops! Please fill all required fields.',
  registrationError: 'Oops! There was a problem with registration.',
  savingError: 'Oops! There was an error while saving.',
  addingJobError: 'Oops! There was an error while adding the job.',
  unknownErrorTryAgain: 'Oops! Unknown error. Please try again.',
  uploadFileError: 'There was an error while uploading.',
  maxFileSizeExceeded: 'File size limit exceeded.',
  minFileDimensionExceeded: 'Image is too small.',
  insertPdfFile: 'Please import a .pdf file.',
  insertDocument: 'Please import a .pdf, .doc or .docx file.',
  selectedFileIsBroken: 'Selected file is corrupted. Check your selection again.',
  somethingWentWrongTryAgainOrContactUs:
    'Oops! Something went wrong. Please try again or contact us',
  somethingWentWrongPleaseRefresh: 'Oops! Something went wrong. Please try refreshing.',
  pleaseRefresh: 'Oops! Try to refresh, please.',
}

const tooltip = {
  countryInputDisabled: 'To change the country, please remove the city first.',
  photoUploadTip:
    'Tip: You can use the same logo you use on your company Facebook page. Just download it and import it here be clicking on the Select button.',
  photoUploadFormat: `
    Import <strong>.jpg, .png or .gif</strong> file. Max size {{maxSize}}Mb.<br /> Recommended dimensions {{dimension}}x{{dimension}}px.`,
  photoFreelancerUploadFormat: `
    Import <strong>.jpg, .png or .gif</strong> file. Max size {{maxSize}}Mb.<br /> Recommended dimensions {{dimension}}x{{dimension}}px.`,
  personalDataInfo:
    "This information is only for the processing of personal information and won't be shown anywhere.",
  gdprDataInfo:
    "This information is only for the GDPR purposes only and won't be shown anywhere.",
  textareaFormatInfo: 'For clickable URL use https://pretlak.com.',
  telephoneInput: "Only for us in case of a problem. Won't be publicly shown anywhere.",
  salary:
    'Let us now how do you value your time. We want to be transparent and shape the market in the right way.',
  certification:
    "If you've done something worth mentioning say it now or never. E.g. cooking class 😉",
  exportToCsvForPackages:
    'Export to CSV is only possible for Exclusive and Premium offers',
  hrUrl: 'Use for connection with ATS / Recruitment system',
  packageExpireDate: 'The validity of the package of offers is unlimited.',
}

const placeholder = {
  yourEmail: 'Your email',
  minimumChars: 'Minimum {{count}} chars',
  city: 'Start typing',
  cityShort: 'Select city',
  aboutCompany:
    'Basic information about company, values, history, accomplishments.. you know..',
  fullName: 'Enter entire name',
  note: 'Enter note here',
  companyName: 'Start typing the company name to see results...',
  salaryNote: 'Add more details / Employee or contractor / bonuses',
  aboutMe:
    "Let's attract a prospective client - sell yourself, mention what do you do or don't do, who you worked with or what's your favorite sneaker brand... Do not add your phone number or email address into this field.",
  education:
    "We neither over- nor underestimate it. It's up to you how you approach this item.",
  portfolio:
    'Now is your chance to describe your projects you are most proud of in more detail. Add links, emojis and so on.. you know what to do..',
  countrySelect: 'e.g Slovakia',
  candidateResponseModalText: 'Your message that we will send to the candidate by e-mail',
  invoiceNote: 'e.g Order number',
  hrUrl: 'e.g https://pretlak.com',
}

const label = {
  requirements: 'Requirements',
  workMail: 'Work email',
  contactMail: 'Contact email',
  nameAndSurname: 'First and last name',
  residenceCity: 'City of permanent residence',
  birthYear: 'Year of birth',
  telephoneNumber: 'Phone number',
  invoiceNote: 'Invoice note',
  hrUrl: 'Link to ATS system',
}

const search = {
  placeholderJobs: 'Search for a dream job or a company ...',
  placeholderFreelancers: 'Search for a freelancer ...',
  placeholderCompanies: 'Search for a company ...',
  lastSearched: 'Last Searched',
  lastSearchedMobile: 'Last',
}

const shortcuts = {
  companyId: 'Company registration number',
  vatID: 'VAT ID (not for CZ companies)',
  vatNumber: 'VAT number',
  postCode: 'Postcode',
  vat: 'VAT',
  forExample: 'e.g',
}

const footer = {
  text: 'Do you need help?',
}

module.exports = {
  actions,
  error,
  tooltip,
  placeholder,
  label,
  search,
  shortcuts,
  footer,
  new: 'New',
  addVideoPlaceholder: 'Insert a link for your video',
  password: 'Password',
  more: 'More',
  free: 'Free',
  wantReceiveEmailWeeklyOffers: 'I want to receive similar offers every week',
  somethingAboutMe: 'Your chance to stand out',
  basicInfoAboutMe:
    'Experience, motivation, a poem, or an important link, stand out in your own way. Companies actually read this and sometimes even make decisions based on it!',
  noOfferFound: 'No offer found.',
  noCompanyFound: 'No company found',
  noCompanyJobs: 'Company has no opened jobs.',
  lastSearched: 'Recent searches',
  loadMore: 'Load more',
  loginTitle: 'Sign in',
  loginMyself: 'Sign in',
  loginYourself: 'Sign in',
  loginYourselfLowercase: 'sign in',
  great: 'Awesome!',
  forgetYourPassword: 'Forgot password?',
  forgottenPasswordDescription:
    "Don't worry, it happens. Tell us your email and we will send you the instructions to restore it",
  addProfile: 'Add profile',
  noRegistrationYet: "Don't have an account?",
  noRegistrationYetFreelancer: "Don't have a created profile yet?",
  registerYourself: 'Sign up',
  registerYourselfFreelancer: 'Sign up',
  moreInfo: 'More info',
  moreInfoShort: 'More info',
  iAmInterested: "I'm interested",
  registerFreelancerProfile: `Register freelancer<br />profile`,
  form: {
    selectOption: 'Select option',
    typeNewPassword: 'Enter new password',
    typeNewPasswordAgain: 'Confirm password',
    saveNewPassword: 'Save new password',
    typeNewEmail: 'Type new email',
    saveNewEmail: 'Save new email',
  },
  toPageTop: 'Top of the page',
  aboutUs: 'About us',
  continue: 'Continue',
  talentBox: {
    text: 'Hundreds of candidates available immediately. You can start hiring right away. Access to the whole CV database upon request.',
    freshCVsDatabase: `Fresh<br />
    database
    <br />
    of CVs`,
    freelancerCategoryWithCount: {
      marketers: '{{count}} Marketers',
      designers: '{{count}} Designers',
      developers: '{{count}} Developers',
      creatives: '{{count}} Creatives',
      nonTech: '{{count}} Non-tech',
    },
    price: 'Price on request',
  },
  weRecommend: 'RECOMMENDED',
  availableNow: 'Available now',
  favorite: 'Favorite',
  category: 'category',
  jobOffers: 'Job offers',
  freelancers: 'Freelancers',
  advantageousOffers: 'Best offers',
  databaseOfCVs: 'CV database',
  advantageousPackages: 'Best offer',
  publicProfile: 'Public profile',
  companySubscriptionEdit: 'Edit Subscription',
  companyInfo: 'Company details',
  addMissingCompanyInfo: 'Fill in missing',
  emailNotificationsSettings: 'Email notifications preferences',
  iWantCandidateMailNotifications:
    'I want to receive emails notifying me about new candidates',
  iWantOffersMailNotifications: 'I want to receive status updates about my offer',
  iWantInoAboutProductsStatus: 'I want to receive news and tips to improve my profile.',
  iWantAllMailNotifications: 'I want to receive all emails',
  telephoneNumberPrefix: '+421',
  multiplationViews: '{{value}} MORE VIEWS',
  colorizeOffer: 'Set offer color',
  colorizeOfferWithValue: 'Highlight - {{value}}',
  thankYou: 'Thank you',
  addJob: 'Add job',
  editJob: 'Edit job',
  duplicateJob: 'Duplicate job',
  countCurrentJobOffers: {
    one: '{{count}} current offer',
    other: '{{count}} current offers',
    many: '{{count}} current offers',
  },
  company: 'Company',
  companies: 'Companies',
  companiesCount: {
    one: '{{count}} company',
    other: '{{count}} companies',
    many: '{{count}} companies',
  },
  jobs: 'Jobs',
  offersCount: {
    one: '{{count}} offer',
    other: '{{count}} offers',
    many: '{{count}} offers',
  },
  jobsCount: {
    one: '{{count}} job',
    other: '{{count}} jobs',
    many: '{{count}} jobs',
  },
  freelancersCount: {
    one: '{{count}} freelancer',
    other: '{{count}} freelancers',
    many: '{{count}} freelancers',
  },
  notFoundTitle: 'You found a lost space.',
  selectPaymentMethod: 'Select a payment method',
  maximumMbSize: 'Max size {{size}}',
  file: 'file',
  insert: 'Enter',
  paymentDue: 'Due date',
  buySuitablePackage: 'Buy value pack',
  iAgreeWith: {
    start1: 'I agree with the',
    subject1: 'processing of personal data',
    purpose1: 'for purposes of receiving exclusive offers and other news from Pretlak.',
    start2: 'I agree with the',
    subject2: 'terms of service',
    purpose2: 'for purposes of creating a personal profile on our web.',
    purpose3: 'for purposes of searching for similar position.',
    end1: 'of the web Pretlak',
  },
  aboutCompany: 'About company',
  companyHasNoFreePositions: 'This company has no vacant positions at the moment.',
  buySummaryTooltip: 'CV database available now. Candidate search can start immediately.',
  permanentEmployment: 'Permanent employment',
  invoicing: 'Invoice',
  paymentInfo: 'Payment details',
  paymentEmail: 'Invoice e-mail',
  weOffer: 'We offer',
  contact: 'Contact',
  viewsCount: 'Views',
  clickCount: 'Clicks',
  type: 'Type',
  dateAdded: 'Added',
  dateUpdated: 'Updated',
  dateSignup: 'Registered',
  action: 'Action',
  largeAmount: 'large amount',
  invoice: {
    name: 'Invoice',
    view: 'View invoice',
    price: 'Total',
    created: 'Created',
    number: 'Invoice number',
    paid: 'Paid',
    unpaid: 'Unpaid',
    afterExpiration: {
      one: '{{count}} day after due date',
      other: '{{count}} days after due date',
      many: '{{count}} days after due date',
    },
  },
  invoices: 'Invoices',
  noInvoicesYet: 'You currently have no invoices.',
  publishToSocialNetwork: 'Publish on social media',
  cardPayment: 'Credit card',
  publishOfferImmediately: 'Publish offer immediately',
  publishOfferAfterPayment: 'Publish offer after payment',
  willDoImmediately: 'Processed after payment',
  viewImmediately: 'Shown immediately',
  hideProfile: 'Hide profile',
  publishProfile: 'Publish profile',
  editProfile: 'Edit profile',
  showPublicProfile: 'Show public profile',
  invoiceBankTransfer: 'Invoice - via bank transfer',
  companiesThatTrustedUsTitle: 'Companies that trust us and used us to find new hires',
  summary: 'Total',
  summaryWithVAT: 'Total with tax',
  summaryWithoutVAT: 'Total without tax',
  iAmOfferingJob: 'Offering a job',
  iAmLookingForJob: 'Looking for a job',
  tellUsWhyAreYouHere: 'Tell us what brings you here?',
  paymentGateway: 'Payment gateway',
  note: 'Note',
  news: 'News',
  deleteProfile: 'Delete profile',
  submitDeleteProfileText:
    'By pressing the button below your profile will be deleted. Forever. Are you sure?',
  profile: 'Profile',
  myProfile: 'My profile',
  proSubscription: 'Pro membership',
  waitingForApproval: 'Awaiting',
  findOutMore: 'Learn more',
  backToJobOffers: 'Back to job offers',
  countOfCanditates: 'Number of candidates',
  added: 'Added',
  updated: 'Updated',
  sharing: 'Sharing',
  topping: 'Boost',
  extraSharing: 'Extra share',
  topOffer: 'Boost offer',
  topOfferDescription: 'You can choose more than one option',
  noReactionsHr:
    'Your offer uses its own Link to the ATS system / recruitment system, in which you will also find the CVs of applicants for this job offer.',
  noReactionsToPostYet: 'So far there are no replies to your offer.',
  squeezTheMostFromOffer: 'Get the maximum out of your offer and add something extra.',
  candidate: 'Candidate',
  date: 'Date',
  countExpiredCandidates: '{{count}} expired candidates',
  package: 'Pack',
  discount: 'Discount',
  offerPrice: 'Offer price',
  buyPackageAndSaveMoney: 'Purchase the offer pack and save!',
  cvModal: {
    vipListTitle: 'Become a VIP candidatet',
    vipListDescription:
      "Fill in these three details and we will automatically add you to our VIP candidate database, which companies regularly review. It's free!",
  },
  duplicateUpsell: {
    title: 'Duplicate the job post!',
    text: "Save time. Don't write the ad again, duplicate it.",
    cta: 'I want to duplicate',
  },
  buyAddonModal: {
    contentTooltip:
      'Offer will be on top of the main page until another replaces it. The job post date of addition is being updated.',
    extraShareText: 'Extra share on social media',
    shareTooltip:
      'Offer will be shared on Facebook, Instagram, LinkedIn and in our weekly newsletter.',
    thankyou: {
      desc1:
        'Boost for 7 days, Extra Share and custom color are active. Sharing will take place within 3 days. The invoice is in your mailbox. 🙌',
      desc2:
        'Boost for 14 days, Extra Share and custom color are active. Sharing will take place within 3 days. The invoice is in your mailbox. 🙌',
      desc3:
        'Extra Share and custom color are active. Sharing will take place within 3 days. The invoice is in your mailbox. 🙌',
      desc4:
        'Boost for 7 days, Extra Share are active. Sharing will take place within 3 days. The invoice is in your mailbox. 🙌',
      desc5:
        'Boost for 14 days, Extra Share are active. Sharing will take place within 3 days. The invoice is in your mailbox. 🙌',
      desc6: 'Boost is active for 7 days. Invoice is in your mailbox. 🙌',
      desc7: 'Boost is active for 14 days. Invoice is in your mailbox. 🙌',
      desc8:
        'Extra share is now active. Sharing will take place within 3 days. Invoice is in your mailbox. 🙌',
      desc9: 'Offer has a custom color set. Invoice is in your mailbox. 🙌',
    },
  },
  promoCodeCopied: 'Promocode has been copied',
  activePackages: 'Active packages',
  active: 'Active',
  used: 'Applied',
  remains: 'Remaining',
  expires: 'Expires',
  renewal: 'Renewal',
  promoCode: 'Promo code',
  promoCodeValidUntil: 'Valid until',
  promoCodeName: 'Enter promocode',
  copyPromoCode: 'Copy promocode',
  packagesOffer: 'Pack offer',
  displayMode: 'View mode',
  accountSettings: 'Account preferences',
  validity: 'Validity',
  countOfOffers: 'Number of offers',
  back: 'Back',
  pricing: 'Pricing',
  magazine: 'Magazine',
  statistics: 'Stats',
  loading: 'Loading',
  salary: 'Salary',
  city: 'City',
  country: 'Country',
  positionName: 'Position name',
  companyName: 'Company name',
  candidateApprovalModalMessage:
    'You match our criteria which means that we will contact you soon.',
  notes: 'Notes',
  typeNoteToCandidate: 'Enter your notes about candidate her...',
  placeForYourPrivateNotes: 'Space for your notes which only you can see.',
  rejectCandidate: 'Reject candidate',
  rejectAndSendMail: 'Reject and send an email',
  rejectWithoutSendMail: 'Reject without sending an email',
  rejectCandidateModalMessage:
    "Unfortunately you don't match our criteria and do not advance to the next round. Good luck in your search.",
  newPassword: 'New password',
  checkPassword: 'Check password',
  submitNewPassword: 'Confirm new password',
  viewAll: 'Show all',
  selectCandidate: 'Select candidate',
  selectAndSendMail: 'Select and send an email',
  selectWithoutSendMail: 'Select without sending an email',
  queryModal: {
    text: 'Due to GDPR regulations candidate details are only available for 3 months from responding to an offer. Then they will be automatically deleted.',
    feedbackText: 'Write us your experience and feedback. Thank you.',
  },
  successPaymentModal: {
    addonColorText: 'Highlighting the offer with custom color',
    wasSuccessFullyDone: 'was successfully processed',
    extraShareNote: 'Extra share will be done within 24 hours.',
    topForDays: 'Boost for {{days}} days',
    freelanceContent: {
      heading: 'Freelancers are unlocked',
      description:
        'You will receive new freelancers by email every week. Select categories.',
    },
    freelanceAddonContent: {
      top: 'boost',
      customColor: 'highlighting the profile with your own color',
      socialShare: 'extra share will be done within 24 hours',
    },
    addonContent: {
      weSentInvoiceToPay: 'Invoice has been sent.',
      shareUpToThreeDays: 'Sharing will happen within 3 days.',
    },
    jobContent: {
      creditCardText:
        'Thank you! The offer will be checked, published on web and shared on social media by the next working day.',
      promoCodeText:
        'The offer will be checked, published on web and shared on social media by the next working day.',
      bankTransferText: `The offer will be checked, published on web and shared on social media after the invoice is paid. Please pay for the invoice which can be found in your mailbox.`,
      cvDatabaseWillBeSend: 'Database of CVs will be sent by email after processing.',
    },
    jobWithPackageContent: {
      creditCardText: 'Purchase of the offer package was successful.',
      bankTransferText: `Purchase of the offer package was successful and the offer was offered. The offer will be checked, published on web and shared on social media by the next working day. Please pay for the invoice which was sent to your email.`,
      talentBoxWillBeSend: 'Talentbox will be sent to you by email after processing.',
      activePromoCode:
        'You can find the active package of job offers in the company profile. View now.',
      successPayment: 'Payment was successful!',
    },
  },
  additionalCompanyModal: {
    tellUs: 'Tell us more about',
    whatCategoryIsYourCompany: 'To what category does your company belong?',
    whereDidYouHearAboutUs: 'Where did you learn about us?',
  },
  copyLink: 'Copy link',
  shareWithEmail: 'Share by email',
  checkWhatToDoForHigherOfferReach:
    'Learn what to do to maximise the reach of your offer! Click here',
  profileWasRemoved: 'Profile was removed!',
  backToFreelancers: 'Back to freelancers',
  certificationTitle: 'Awards, certifications',
  portfolio: 'Portfolio',
  otherAttachment: 'Other attachment',
  portfolioUploadError:
    'Portfolio / Other attachment failed to upload. Please try again.',
  cvUploadError: 'Resume failed to upload. Please try again.',
  education: 'Education',
  backToIntro: 'Back to start',
  almostDone: 'Almost done!',
  recoverPasswordInstructionSentToMailCompany:
    "Instructions to reset your password were sent to your email. Soon you'll be back with us.",
  recoverPasswordInstructionSentToMail:
    "Instructions to reset your password were sent to your email. Soon you'll be back with us.",
  yourPasswordSuccessfullyRestored:
    'Your password has been reset. Sign in and enjoy Pretlak.',
  yourPasswordSuccessfullyRestoredFreelancer:
    'Your password has been reset. Sign in and enjoy Pretlak.',
  passwordReset: 'Reset password',
  hurayYouCanAddYourNewPassword: 'Hooray! You can enter your new password and sign in.',
  requiredField: 'Required',
  buyItemAfterSubmitText:
    'After confirmation, we will issue you an invoice worth {{price}} + VAT and create a package with an active promocode.',
  buyPackageModal: {
    success: {
      title: 'Thank you for purchasing the package. We have sent you a payment invoice.',
      promocodeText: 'Promocode "{{code}}" is active. Show all packages.',
    },
    content: {
      contactText: 'Do you need more info? Contact us',
      callUs: 'Call',
    },
  },
  topBar: {
    companies: 'Discover freelancers!',
    freelancers: 'Join {{count}} freelancers!',
    freelancersWithoutNumber: 'Join freelancers!',
    freelancersProStart: `Switch to `,
    freelancersProEnd: ` and get more!`,
  },
  offer: {
    default: 'offer',
    one: '{{count}} offer',
    other: '{{count}} offers',
    many: '{{count}} offers',
  },
  yourCompanyProfileState: 'status of your company profile',
  state: 'Status',
  year: 'year',
  price: 'price',
  priceAsk: 'On request',
  priceAskTitle: `I am interested in the {{package}} infinite package`,
  days: {
    one: 'day',
    other: 'days',
    many: 'days',
  },
  boostOnWeb: 'Boost on the web for {{count}} days',
  categories: 'Categories',
  companyLogo: 'Company logo',
  viewsWithValue: '{{value}} views',
  selectCategory: 'Select category',
  selectSkill: 'Select level',
  changePositionNameNote:
    'The position name can be changed within 10 days of the offer being published.',
  infoAboutCandidatesWillRemovedAfterThreeMonths:
    'Due to the GDPR Regulation, information on applicants is only available for 3 months',
  promocodeAvailableImmediately: 'Available now',
  countOfCandidates: 'Candidates',
  offerHasBeenClosed: 'Offer has been close',
  suit: 'Suitable',
  doesNotSuit: 'Not suitable',
  attachments: 'Attachments',
  cv: 'CV',
  other: 'Other',
  downloadCv: 'Download CV',
  downloadPortfolio: 'Download portfolio',
  upsellTop: {
    title: 'Boost!',
    text: 'Get 3 times more views. Reach out to new applicants.',
    cta: 'I want to boost',
  },
  cookiesBanner: {
    heading: 'Cookies!',
    text: "The thing is that our web stores cookies on your device. Time to freak out? Not at all. We use cookies just to remember you and to collect some information about how you use our web. Because of that your experience will be even better. Here, you'll find",
    textMobile:
      'Our website stores cookies on your mobile device. We only use these to remember you and give you some information about how you use our website. All for your better experience. Here you will find',
    agree: 'Allow all',
    disagree: 'Reject',
    save: 'Save preference',
    settings: 'Customize',
    settingsHeading: 'Cookies preferences',
    moreInfo: 'more info',
    lessInfo: 'less info',
    functional: 'Functional',
    required: 'Required',
    optional: 'Optional',
    analytics: 'Analytical',
    ad: 'Advertising',
    duration: 'Duration',
    description: 'Description',
    processor: 'Processor',
    date: {
      year: 'year',
      years: 'years',
      month: 'month',
      months: 'months',
      days: 'days',
      day: 'day',
    },
    detail: {
      general:
        "We use cookies to analyse information about you, our visitor, to improve your experience and to show you personalised content, because everybody wants tailor-made content, don't they?",
      functional:
        "Cookies help us create well arranged websites by enabling basic functionality such as site navigation and access to protected website parts. Simply said - it's not possible without Cookies so it's not possible to reject them.",
      analytics:
        'Analytical Cookies help us to better understand how to communicate with you by collecting information. Anonymously, of course.',
      ad: 'We use them to follow your preferences, ad targeting or showing marketing and advertising (also on third party websites) that might interest you as our customer. Everything according to these preferences. Marketing Cookies also use third party tools. To use them we need your approval. Are you in?',
    },
    descriptions: {
      ccSettings: 'Serves to save cookie preferences.',
      session: 'Assures correct user login.',
      cv: 'Serves to auto-fill information about user while sending CV.',
      freelancer: 'Serves to auto-fill user information when contacting freelancer.',
      visited: 'Serves to tag seen applicants in company profile.',
      gaBasic: 'ID used for user identification.',
      ga24: 'ID serves to identify users in period of 24 hours after last activity.',
      gaAds:
        'Uses Google AdSense to experiment with advertising effectivity on websites using its services.',
      metaAds:
        'Uses Meta to serve advertising products, such as real-time offers from third party advertisers.',
    },
  },
  jobDescription: 'Job description',
  salaryPerHour: 'Hourly',
  residence: 'Residence',
  from: 'from',
  bonus: {
    one: 'bonus',
    other: 'bonuses',
    many: 'bonuses',
  },
  ppcCampaign: 'PPC campaign',
  onlineCampaign: 'PPC online campaign',
  countOfCVs: 'CVs count',
  iWantBoostPrice: 'Boost offer - {{price}} € + VAT',
  iWantBoostPriceWithoutVat: 'Boost offer - {{price}} €',
  iWantBoost: 'Boost offer',
  companyCategoryName: {
    AGENCY: 'Agencies & studios',
    MEDIA: 'Media',
    TECH: 'Tech & Innovation',
    TELCO: 'Telco, finance, public administration',
    CULTURE: 'Culture & Non-Profit Sector',
    OTHERS: 'Other',
  },
  companySubcategoryName: {
    AUDIO_VIDEO: 'Audio & Video Production',
    WEB_DESIGN_DEVELOPMENT: 'Web design & development',
    GRAPHICS_DESIGN: 'Graphic Design',
    SOCIAL_MEDIA_CONTENT: 'Social Media & Content',
    PR_COMMUNICATION: 'PR and communication',
    EVENT: 'Event',
    PRINT_ADVERTISING: 'Print & Ad Production',
    MEDIA_AGENCY: 'Media Agency',
    HR: 'HR',
    FULLSERVICE: 'Full service',
    IT_PROJECTS_DEVELOPMENT: 'IT Project Development',
    TV: 'TV',
    RADIO: 'Radio',
    PRINT: 'Print',
    PUBLISHING_HOUSE: 'Publisher',
    INDOOR_OUTDOOR: 'Indoor / Outdoor',
    STARTUPS: 'Startups',
    E_COMMERCE: 'E-commerce',
    SAAS: 'Saas',
    GAMING: 'Gaming',
    BANKING: 'Banking',
    INSURANCE: 'Insurance',
    TELECOMMUNICATIONS: 'Telecommunications',
    GOVERNMENT: 'Public Administration',
    GASTRONOMY_HOSPITALITY: 'Gastro & Hospitality',
    CULTURE: 'Culture',
    ART: 'Art',
    CIVIC_ASSOCIATION: 'Civic Association',
    NON_PROFIT_ORGANIZATIONS: 'Nonprofits',
    EDUCATION: 'Education',
    CONSTRUCTIONS: 'Construction and Real Estate',
    ARCHITECTURE_INTERIOR_DESIGN: 'Architecture and Interior Design',
    RETAIL: 'Retail',
    OTHER_CATEGORY: 'Other category',
  },
  cvs: 'CVs',
  finished: 'Finished',
  notUsed: 'Not used',
  noCountryFound: 'Country not found',
  with: 'with',
  exportTo: 'Export to {{shortcut}}',
  activeViews: 'Active views',
  ads: {
    title: `ADVERTISING SPACES <br />PRETLAK ADS!`,
    price: `Price from €250 <span class="addon">+ VAT</span>`,
    views: '{{count}} website views per month',
    social: '{{count}} fans on social media',
    newsletter: '{{count}} newsletter subscribers',
    description: `Make your brand visible through our advertising spaces, which target exactly where you need. We offer various <strong>web formats, social media, or newsletters</strong>.`,
    cta: 'View the price list',
  },
  hotCompany: {
    label: '🔥 HOT',
    company: 'company',
    text1:
      'Be HOT and gain more! Take advantage of the benefits that will put your company ahead. For more information,',
    email: 'write to us',
    text2: 'or call us at',
  },
}
