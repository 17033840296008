import { ICity, ICountry } from './company'

export enum FreelancerType {
  STANDARD = 'standard',
  PREMIUM = 'premium',
}

type SocialShareQueues = {
  fb: string[]
  ig: string[]
  li: string[]
  tw: string[]
}

export interface IProfile {
  _id: string
  shortId: string
  status: string
  available: boolean
  public: boolean
  name: string
  email: string
  title: string
  skill_level: {
    _id: string
    slug: string
    name: string
  }
  job_category: {
    _id: string
    name: string
    slug: string
  }
  country: ICountry
  city: ICity
  job_salary_min: number
  job_salary_max: number
  job_salary_note: string
  top: boolean
  detail: {
    about: string | null
    education: string | null
    portfolio: string | null
    awards: string | null
    web: string | null
    linkedin: string | null
    instagram: string | null
    dribble: string | null
    behance: string | null
    github: string | null
    gitlab: string | null
    twitter: string | null
    youtube: string | null
    vimeo: string | null
    facebook: string | null
    tiktok: string | null
    promoVideo: string | null
  }
  modals: string[]
  signup_date: string
  stats: {
    views: number
  }
  share: {
    fb: boolean
    ig: boolean
    li: boolean
    tw: boolean
  }
  tags: Array<{ _id: string; name: string; slug: string }>
  backgroundColor?: string
  picture?: string
  freelancerType?: FreelancerType
  favouriteFreelancers: IProfile[]
  addons?: {
    topStart?: string
    topCount?: number
    socialShare?: {
      pending: SocialShareQueues
      done: SocialShareQueues
      failed: SocialShareQueues
    }
    topSponsoredStart?: string
  }
  notifications?: {
    products: boolean
  }
}
