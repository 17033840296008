import { PRODUCT } from 'server/constants'

enum ContractType {
  TPP = 'TPP',
  INVOICE = 'INVOICE',
}

export interface IJobProperties {
  email: string
  companyPublicName: string
  companyJobLogo: string
  telephone: string
  jobLevel: string
  jobPosition: string
  skillLevels: string[]
  jobCategory: string
  jobCity: string[] | []
  remote: boolean
  jobType: string
  salaryNote: string
  about: string
  requirements: string
  benefits: string
  promoCode: string
  salarySingleFromCheckbox: boolean
  salaryFrom: string
  salaryTo: string
  salarySingleFrom: string
  salaryTpp: boolean
  salaryInvoice: boolean
  tagsSelected: string[]
  customColor: boolean
  customColorValue: string
}

interface JobAddonProperties {
  job_id: string
}

interface AddonColorProperties extends JobAddonProperties {
  color: string
}

interface TalentboxProperties {
  category: string
}

enum JobCode {
  JOB_STANDARD = 'JOB_STANDARD',
  JOB_PREMIUM = 'JOB_PREMIUM',
  JOB_EXCLUSIVE = 'JOB_EXCLUSIVE',
}

enum StandaloneSimpleProductCode {
  ADDON_SOCIAL_SHARE = 'ADDON_SOCIAL_SHARE',
  ADDON_TOP = 'ADDON_TOP',
  FREELANCERS_ALL = 'FREELANCERS_ALL',
  FREELANCERS_CATEGORY = 'FREELANCERS_CATEGORY',
}

enum PackageCode {
  PACKAGE_STANDARD_SMALL = 'PACKAGE_STANDARD_SMALL',
  PACKAGE_STANDARD_MEDIUM = 'PACKAGE_STANDARD_MEDIUM',
  PACKAGE_STANDARD_LARGE = 'PACKAGE_STANDARD_LARGE',
  PACKAGE_PREMIUM_SMALL = 'PACKAGE_PREMIUM_SMALL',
  PACKAGE_PREMIUM_MEDIUM = 'PACKAGE_PREMIUM_MEDIUM',
  PACKAGE_PREMIUM_LARGE = 'PACKAGE_PREMIUM_LARGE',
  PACKAGE_EXCLUSIVE_SMALL = 'PACKAGE_EXCLUSIVE_SMALL',
  PACKAGE_EXCLUSIVE_MEDIUM = 'PACKAGE_EXCLUSIVE_MEDIUM',
  PACKAGE_EXCLUSIVE_LARGE = 'PACKAGE_EXCLUSIVE_LARGE',
}

export enum FreelancerSubscriptionCode {
  SUBSCRIPTION_FREELANCERPROFILE_MONTHLY = 'SUBSCRIPTION_FREELANCERPROFILE_MONTHLY',
  SUBSCRIPTION_FREELANCERPROFILE_YEARLY = 'SUBSCRIPTION_FREELANCERPROFILE_YEARLY',
}

export enum FreelancersUnlockSubscriptionCode {
  SUBSCRIPTION_FREELANCERSUNLOCK_MONTHLY = 'SUBSCRIPTION_FREELANCERSUNLOCK_MONTHLY',
  SUBSCRIPTION_FREELANCERSUNLOCK_YEARLY = 'SUBSCRIPTION_FREELANCERSUNLOCK_YEARLY',
}

type ProductAdditional = {
  type: string
  price: number
  text: string
  days?: number
  icon?: string
  size?: string | number
  translation?: {
    sk: string
    cs: string
    en: string
  }
}

export type JobProduct = {
  code: JobCode
  properties: IJobProperties
} & ProductAdditional

type AddonColorProduct = {
  code: 'ADDON_CUSTOM_COLOR'
  properties: AddonColorProperties
} & ProductAdditional

type StandaloneSimpleProduct = {
  code: StandaloneSimpleProductCode
  properties: { [key: string]: any }
} & ProductAdditional

type PackageProduct = {
  code: PackageCode
  properties: { [key: string]: any } | IJobProperties
} & ProductAdditional

type TalentBoxProduct = {
  code: 'TALENTBOX_CATEGORY'
  properties: TalentboxProperties
} & ProductAdditional

export type FreelancerSubscriptionProduct = {
  code: FreelancerSubscriptionCode
  properties: { [key: string]: any }
} & ProductAdditional

export type FreelancersUnlockSubscriptionProduct = {
  code: FreelancersUnlockSubscriptionCode
  properties: { [key: string]: any }
} & ProductAdditional

export type Product =
  | JobProduct
  | AddonColorProduct
  | StandaloneSimpleProduct
  | PackageProduct
  | TalentBoxProduct
  | FreelancerSubscriptionProduct
  | FreelancersUnlockSubscriptionProduct

export default class ProductUtils {
  static getProductByCode(code: string, data: object = PRODUCT) {
    let result = null

    for (let prop in data) {
      if (prop == 'code') {
        if (data[prop] == code) {
          return data
        }
      }

      if (data[prop] instanceof Object) {
        result = ProductUtils.getProductByCode(code, data[prop])
        if (result) {
          break
        }
      }
    }
    return result
  }

  static getProductsWithProperties(productsProps: any[]): Product[] {
    const products: Product[] = []

    productsProps.forEach((productProp) => {
      let product = {
        properties: productProp.properties,
        ...ProductUtils.getProductByCode(productProp.type),
      }
      products.push(product)
    })

    return products
  }

  static getJobInfo(jobLevel: string) {
    switch (jobLevel) {
      case '1':
        return PRODUCT.JOB.PREMIUM
      case '3':
        return PRODUCT.JOB.EXCLUSIVE
      case '2':
        return PRODUCT.JOB.STANDARD
    }
  }

  static getCheckoutProducts(products: Product[]) {
    let checkoutProducts = []
    let jobData = null
    const jobProduct = products.find(
      (product) =>
        product.code.startsWith('JOB_') ||
        (product.code.startsWith('PACKAGE_') && Object.keys(product.properties).length)
    )
    products.forEach((product: Product) => {
      let properties: any

      if (
        product.code.startsWith('JOB_') ||
        (product.code.startsWith('PACKAGE_') && Object.keys(product.properties).length)
      ) {
        jobData = product.properties as IJobProperties
        properties = {
          draftId: jobData.draftId || null,
          company_logo: jobData.companyJobLogo,
          company_name: jobData.companyPublicName,
          category: jobData.jobCategory,
          cities: jobData.jobCity,
          position: jobData.jobPosition,
          type: jobData.jobType,
          description: jobData.about,
          requirements: jobData.requirements,
          benefits: jobData.benefits,
          is_remote: jobData.remote,
          salary_from: jobData.salarySingleFromCheckbox
            ? parseInt(jobData.salarySingleFrom)
            : parseInt(jobData.salaryFrom),
          salary_note: jobData.salaryNote,
          skillLevels: jobData.skillLevels,
          tags: jobData.tagsSelected,
          currency: jobData.currency,
          contract_types: [],
          email: jobData.email,
          telephone: jobData.telephone,
          hr_url: jobData.hrUrl,
          promoVideo: jobData.promoVideo,
        }
        if (!jobData.salarySingleFromCheckbox) {
          properties.salary_to = parseInt(jobData.salaryTo)
        }
        if (jobData.salaryInvoice) {
          properties.contract_types.push(ContractType.INVOICE)
        }
        if (jobData.salaryTpp) {
          properties.contract_types.push(ContractType.TPP)
        }
        if (
          jobData.customColor &&
          (product.code.startsWith('PACKAGE_EXCLUSIVE_') ||
            product.code === PRODUCT.JOB.EXCLUSIVE.code)
        ) {
          properties.color = jobData.customColorValue
        }
      } else if (product.code === PRODUCT.ADDON.CUSTOM_COLOR.code) {
        if (jobProduct) {
          properties = {
            color: (jobProduct.properties as IJobProperties).customColorValue,
          }
        } else {
          properties = {
            color: (product.properties as AddonColorProperties).color,
            job_id: (product.properties as AddonColorProperties).job_id,
          }
        }
      } else if (product.code === PRODUCT.TALENTBOX.CATEGORY.code) {
        properties = {
          category: (jobProduct?.properties as IJobProperties).jobCategory || 'n/a',
        }
      } else if (
        [
          PRODUCT.ADDON.TOP.code,
          PRODUCT.ADDON.TOP_LONG.code,
          PRODUCT.ADDON.SOCIAL_SHARE.code,
        ].includes(product.code)
      ) {
        properties = {
          job_id: (product.properties as JobAddonProperties).job_id,
        }
      } else if (product.code === PRODUCT.FREELANCERS.CATEGORY.code) {
        properties = {
          category: (product as StandaloneSimpleProduct).properties.category || undefined,
        }
      } else {
        properties = {}
      }

      checkoutProducts.push({
        type: product.code,
        properties,
      })
    })

    return checkoutProducts
  }
}
