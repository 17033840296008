module.exports = {
  introductionText:
    "Choose the type of offer and get relevant CVs, increased awareness of the company and endless fame in the community. Let's do it?",
  badgeLabel: 'Unlimited offers',
  priceNote: 'Price without VAT / job',
  package: {
    exclusive: 'Exclusive',
    exclusiveNote: '+ VAT / job',
    exclusiveSale: '299 €',
    premium: 'Premium',
    premiumNote: '+ VAT / job',
    premiumSale: '99 €',
    standard: 'Standard',
    standardNote: '+ VAT / job',
    standardSale: '79 €',
    complete: 'Whole set',
    category: 'Category',
    title: '{{packageName}} pack',
    smallTitle: 'Small {{packageName}} pack',
    mediumTitle: 'Medium {{packageName}} pack',
    largeTitle: 'Unlimited {{packageName}} pack',
  },
  addon: {
    companyLogo: 'Company logo',
    jobDuration: 'Offer duration',
    topVisibility: 'TOP display - {{count}} days',
    topVisibilityDescription: 'TOP display on homepage',
    durationDays: '{{count}} days',
    premiumVisibility: 'Premium display - {{count}} days',
    standardVisibility: 'Basic display',
    newsletter: 'Included in the newsletter',
    includedInNewsletter1x: 'Included once in the newsletter',
    includedInNewsletter2x: 'Included twice in the newsletter',
    ppcCampaign: 'PPC campaign',
    ownColor: 'Highlighting in value {{price}} €',
    talentbox: 'Access to CV database',
    video: 'Video in job offer',
    facebook: 'Facebook post',
    instagram: 'Instagram post',
    linkedin: 'LinkedIn post',
  },
  customPackageUpsell: {
    text: 'Do you need an unlimited package, or a custom package offer?',
    cta: 'Write to us',
  },
  selected: 'Selected',
  notebox: {
    headline: 'Find co-workers affordably!',
    text: 'Buy 5 or 10 jobs in a single package and save up to 30%!',
    cta: 'Show pricing',
  },
  mostFavorite: 'MOST POPULAR',
  viewAdvantageousPackage: 'Show Best offer',
  unavailableForHRAgencies: 'Not available for HR agencies',
  tooltip1:
    'This offer will be published forever. You can close it at any time in your profile.',
  tooltip2:
    'Make yourself stand out and use your own custom color for the offer background.',
  tooltip3: 'Higher position on the web + logo',
  tooltip4: 'Basic position on the web',
  tooltip5: 'Top position on the web + logo + color highlight',
  tooltipDb:
    'Based on the advertised position, we will select relevant applicants from the CV Database',
  packagesOfferHead: 'Value offer packages',
  smallPackage: 'Small package',
  mediumPackage: 'Medium package',
  infinitePackage: 'Infinite package',
  smallStandardPackage: 'SMALL STANDARD PACKAGE',
  mediumStandardPackage: 'MEDIUM STANDARD PACKAGE',
  infiniteStandardPackage: 'INFINITE STANDARD PACKAGE',
  smallPremiumPackage: 'SMALL PREMIUM PACKAGE',
  mediumPremiumPackage: 'MEDIUM PREMIUM PACKAGE',
  infinitePremiumPackage: 'INFINITE PREMIUM PACKAGE',
  smallExclusivePackage: 'SMALL EXCLUSIVE PACKAGE',
  mediumExclusivePackage: 'MEDIUM EXCLUSIVE PACKAGE',
  infiniteExclusivePackage: 'INFINITE EXCLUSIVE PACKAGE',
  promocodeTitle: {
    'malý balík': 'Small pack',
    'nekonečný balík': 'Infinite pack',
    'stredný balík': 'Middle pack',
    default: 'Promo code',
  },
  promocodeSubtitle: {
    exkluzív: 'Exclusive',
    prémium: 'Premium',
    štandard: 'Standard',
  },
  help: {
    start: 'Do you need help or have any questions?',
    contactUs: 'Send us an email',
    end: 'or call us at',
  },
}
