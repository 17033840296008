module.exports = {}
const common = require('./common')
const notifications = require('./notifications')
const filter = require('./filter')
const label = require('./label')
const stats = require('./stats')
const jobs = require('./jobs')
const navigation = require('./navigation')
const freelancers = require('./freelancers')
const pricing = require('./pricing.js')
const testimonials = require('./testimonials')
const magazine = require('./magazine')
const signup = require('./signup')
const meta = require('./meta')
const invoices = require('./invoices')
const countries = require('./countries')
const manifest = require('./manifest')

module.exports = {
  common,
  notifications,
  filter,
  label,
  stats,
  jobs,
  navigation,
  freelancers,
  pricing,
  testimonials,
  magazine,
  signup,
  meta,
  invoices,
  countries,
  manifest,
  newsletter: {
    title: 'New job posts',
    introduction:
      'Every week we will fill your mailbox with the most up to date job offers. Just choose a category and enter your email.',
    confirmDesc: `Thank you for your interest. Now we still have to confirm your e-mail address, so it's all right. To complete this cool process, please click on the link in the email we just sent you.`,
    successDesc: `Cool, you've been added to our newsletter. You can expect a fresh job offer every week!`,
    confirmTitle: 'We are 99% there ...',
  },
  aboutUs: {
    stat1: 'Launch of the website',
    stat2: 'Job seekers',
    stat3: 'Sent CVs',
    stat4: 'Added offers',
    p1: "Hi, we're three Thomases, founders of Pretlak. Modern job website with the goal to collect the most up to date job offers from marketing, design, IT, startups, e-commerce and other related industries.\n",
    p2: 'On the one hand, there are amazing work opportunities from well known companies, on the other skilled people from the industry looking for these opportunities. We want to serve you all of this in the easiest way possible, without any unnecessary clicks, because we believe that simple does the job.',
    p3: 'Besides that we support the creative community (which we are a part of), freelancers, remote jobs and quality over quantity.',
    p4: 'We are excited that you are a part of this! ✌️️',
  },
  og: {
    defaultTitle:
      'Jobs for developers, designers, marketers, IT and Remote | Pretlak.com',
  },
  email: {
    welcome: {
      subject: '👋 Good job!',
    },
    noJobs: {
      subject: '👋 Need advice?',
    },
    sendPublished: {
      subject: '✅ The job offer has been approved',
    },
    sendNotPublished: {
      subject: '⛔️ The job offer was not approved',
    },
    jobApplyUser: {
      subject: '✉️ Your request has been sent',
    },
    jobApplyCompany: {
      subject1: 'Cool, you have a new candidate!',
      subject2: 'You have a new candidate!',
    },
    jobEndClosed: {
      subject: `🤝 The offer has been closed`,
    },
    jobEndExpired: {
      subject1: '🔝 You can boost your offer',
      subject2: '💎 Premium view ended',
      subject3: '👑 Boosted view ended',
    },
    jobCloseReminder: {
      subject: '🔔 The job offer is still active',
    },
    jobCloseLastReminder: {
      subject: '⛔️ The job offer will be deleted...',
    },
    jobPaymentReminder: {
      subject: '🧾 The invoice is unpaid',
    },
    jobLowPerf: {
      subject: '🔔 The job offer is still active',
    },
    packageExpired: {
      subject: `📦 The advantageous package has expired`,
    },
    forgotPassword: {
      subject: '🔑 Forgot your password?',
    },
    sendFreelancerPublished: {
      subject: '👋 Welcome between us!',
    },
    sendFreelancerAdminContact: {
      subject: '👋 Your freelancer profile needs editing',
    },
    sendJobDraftAdminContact: {
      subject: '👋 Your job is waiting for completion',
    },
    candidateRejection: {
      subject: "😞 Um, it didn't work out this time ...",
    },
    candidateApproval: {
      subject: '🎉 Good news!',
    },
    failedPayment: {
      subject: '🚨 Payment failed...',
    },
    invoicePaid: {
      subject: '🚨 Important invoice notification',
    },
    sendNoActiveCompany: {
      subject: '👋 We haven’t seen you for a while…',
    },
    promocodeWillExpire: {
      subject: `🚨 Promo code will expire soon`,
    },
    promocodeStillOnline: {
      subject: `🚨 You have unused job offers...`,
    },
    jobDraft: {
      subject: `✏️ The unfinished job post...`,
    },
    sendFreelancerContact: {
      subject: '💡 Ah, a new proposal for cooperation',
    },
    topFreelancer: {
      subject: 'Do you want more clients? Top your profile 👀',
    },
  },
  companyModal: {
    heading: 'Find co-workers at a better price',
    item: "Purchase a package of 5 or 10 jobs and unlock hiring to the fullest. it's faster and at a better price. You can choose from STANDARD, PREMIUM, or EXCLUSIVE packages and save up to 30%.",
    cta: 'View pricing',
  },
  loginBanner: {
    pill: 'WOW',
    heading: 'FIND <br/>CO-WORKERS <br/>AFFORDABLY',
    text: 'Buy 5 or 10 jobs in a single package and save up to 30%.',
    badge: '30 % OFF',
    cta: 'Go, go',
  },
  profileModal: {
    heading: 'News!',
    subheading: 'Go PRO and get more! Just for {{price}}&nbsp;€/month.',
    cta: 'Show more information!',
    items: [
      '🔥 Photo with your name',
      '🔥 Video on your profile',
      '🔥 PPC campaign',
      '🔥 TOP display on the web',
      '🔥 Promotion on social media',
      '🔥 Custom color profile',
    ],
  },
  hiringUpsell: {
    titleRaw: "Don't have time to waste time on hiring?",
    title: "Don't have time<br />to waste time<br />on hiring?",
    text: "I'm Peter and I'll tell you<br />how we'll handle the entire hiring process for you!",
    textOneLine:
      "I'm Peter and I'll tell you how we'll handle the entire hiring process for you!",
    cta: 'Email me!',
    writeMe: 'Email me',
    orCallMe: 'or call',
    smallBannerText: 'I can handle Job add and the entire hiring for you! Call',
  },
}
