module.exports = {
  noJobsYetContinueWithAddJob:
    'Zatiaľ tu nič nemáte. Pokračujte kliknutím na tlačidlo "Pridať job".',
  activeOffers: 'Aktívne ponuky',
  closedOffers: 'Uzatvorené ponuky',
  viewCandidates: 'Zobraziť uchádzačov',
  contactEmail: 'Kontaktný e-mail',
  contactEmailTooltip:
    'Tento e-mail slúži na zasielanie notifikácií o nových záujemcoch.',
  contactTelephone: 'Kontaktné telefónne číslo',
  whatDoWeOffer: 'Čo ponúkame?',
  whatDoWeOfferPlaceholder: 'Benefity, z ktorých nový kolega (možno) padne na zadok',
  republishJobDescription:
    'Už ste len krok od toho, aby bola vaša ponuka opäť aktuálna. Stačí si vybrať – ŠTANDARD alebo PRÉMIUM? Následne ju skontrolujeme a do 24 hodín zverejníme.',
  addJobDescription:
    'Vyberte si typ ponuky. Do 24-hodín ju skontrolujeme a zverejníme na webe. Získate nekonečný čas trvania, vysokú relevanciu CV-čiek a zvýšenie povedomia vašej firmy v našej komunite! Ideme na to?',
  addJobBadge: 'Nekonečné ponuky',
  companyNameLabel: 'Názov firmy',
  companyNamePlaceholder: 'Pretlak - nepoužívajte s.r.o., spol. s.r.o., a.s., ...',
  companyNameTooltip:
    'Napíšte názov vašej značky / agentúry / firmy tak ako sa bude pri ponuke zobrazovať',
  jobCategoryLabel: 'Kategória jobu',
  remotePositionLabel: 'Remote pozícia',
  colabFormLabel: 'Forma spolupráce',
  salaryInGrossLabel: 'Plat v hrubom',
  mentionOnlyLowerSalaryLimit: 'Uvádzam len spodnú hranicu platu',
  salaryNoteLabel: 'Poznámka k platu',
  payOffTypeLabel: 'Spôsob vyplácania',
  scopeOfWorkPlaceholder:
    'Zhrnutie toho, čo uchádzač môže očakávať od danej pozície. KISS.',
  requirementsPlaceholder:
    'Aký by mal byť uchádzač o túto pozíciu - vzdelanie, skills, zmysel pre humor, obľúbené zviera atď. Nezadávaj do profilu svoje telefónne číslo ani e-mail.',
  cvErrorTitle: 'Ups, niekde sa stala chybička.',
  requestNotSendTryAgain: 'Tvoja žiadosť nebola odoslaná. Skús to prosím znova.',
  requestSuccessfullySent: 'Žiadosť úspešne odoslaná!',
  cvSuccessDescription:
    'Firmy môžu mať výberové konanie otvorené aj niekoľko týždňov. Prosíme ťa o trpezlivosť pri čakaní na odpoveď.',
  problemHasOccured: 'Nastal problém!',
  editErrorDescription:
    'Počas ukladania vašich zmien nastal problém, prosím skúste úpravu ešte raz, v prípade pretrvávajúcich problémov kontaktujte podporu',
  editNotAllowedDescription:
    'Na úpravu tejto ponuky nemáte dostatočné práva. Prosím overte vaše prihlásenie. V prípade pretrvávajúcich problémov, prosím kontaktujte podporu na',
  goodJobTitle: 'Dobrá práca!',
  editSuccessDescription:
    'Vaša ponuka bola úspešne upravená. Úpravu ponuky môžete spraviť kedykoľvek, pred aj po zverejnení ponuky. URL adresu pre úpravu vašej ponuky nájdete v potvrdzovacom emaily o schválení ponuky.',
  republishUnknownTitle: 'Hm, narazili sme na problém!',
  republishUnknownDescription:
    'Vyzerá, že duplikovanie nebude možné. Tento link je zrejme prepojený s iným firemným profilom. Skúste to znova alebo pokračujte prihlásením pod iným kontom.',
  similarJobs: 'Podobné joby',
  lookIntoUnpublishedOffer: 'Náhľad nepublikovanej ponuky',
  tenderHasBeenClosed: 'Výberové konanie bolo uzatvorené',
  offerHasExpired: 'Platnosť tejto ponuky skončila',
  offerIsPaused: 'Ponuka je pozastavená',
  higherSalaryAttractMoreCandidates: 'Vačší plat priláka viac uchádzačov.',
  salaryNoteText:
    'Na základe našich štatistík je priemerná odmena na túto pozíciu od {{value}} € (full-time). Prihoďte trošku viac',
  upgradeJobUpsellHeading: 'Aktuálne máme {{count}} ponúk pre {{categoryName}}',
  upgradeJobUpsellText:
    'Zmeňte typ ponuky na PRÉMIUM. Ponuka bude viditeľnejšia a bude mať o +50% väčší dosah.',
  closeJob: 'Uzatvoriť job',
  closeJobDescription:
    'Vaša ponuka sa uzavrie a nebude sa zobrazovať na webe. Všetky informácie k nej budete mať stále dostupné vo vašom firemnom profile.',
  pauseJob: 'Pozastaviť job',
  pauseJobDescription:
    'Vaša ponuka sa nebude dočasne zobrazovať na webe. Všetky informácie k nej budete mať stále dostupné vo vašom firemnom profile.',
  statusText: {
    waiting: '🕔 Čaká na schválenie',
    expired: '⚠️ Expirovaná',
    unpublished: '🔕 Pozastavená',
    banned: '⛔️ Zamietnutá',
    closed: '🔒 Uzatvorená',
    online: 'Online',
    draft: '✏️ Rozpracovaná',
  },
  shareOffer: 'Zdieľať ponuku',
  candidateHasAddedNote: 'Uchádzač má pridanú poznámku',
  candidateUpsellText:
    'Psst. Pozrite na pretlak freelancerov. Nájdete tam {{count}} ľudí, ktorí na vás čakajú.',
  approvalEmailSentToCandidate: 'Uchádzačovi bol odoslaný e-mail.',
  approvalEmailSentToCandidateDate: 'Uchádzačovi bol odoslaný e-mail. ({{date}})',
  rejectionEmailSentToCandidate: 'Uchádzačovi bol odoslaný e-mail.',
  rejectionEmailSentToCandidateDate: 'Uchádzačovi bol odoslaný e-mail. ({{date}})',
  doNotBeWorryClickButton:
    'Nebojte sa, stlačením tlačidla sa uchádzač zatiaľ nič nedozvie.',
  cooperationFormShort: 'Forma',
  todayOnly: 'Už len dnes!',
  daysLeft: {
    one: 'Už len {{count}} deň',
    other: 'Ešte {{count}} dni',
    many: 'Ešte {{count}} dní',
  },
  jobLevelName: {
    premium: 'Prémium',
    exclusive: 'Exkluzív',
    standard: 'Štandard',
  },
  candidateDefaultResponseMessage: `Ahoj {{candidateName}}.[[nl]][[nl]]Ďakujeme za záujem pracovať na pozícii {{jobPosition}}. {{defaultMessage}}[[nl]][[nl]]S pozdravom, Tím {{teamName}}`,
  jobFormEdit: 'Upraviť',
  jobFormPreview: 'Náhľad',
  currency: 'Mena',
  upsellHowToJobPost: {
    strong: 'Chcete napísať ponuku, ktorá zaujme?',
    regular: 'Pripravili sme jednoduchý návod ako nato.',
  },
  refreshModal: {
    title: `Vaša ponuka je na {{num}}. pozícii!`,
    titleAlt: `Vaša ponuka je na 99+ pozícii!`,
    description: `Pracovná ponuka <strong> {{jobName}} </strong> bola obnovená. <br/>Vašu ponuku môžete topovať, alebo aj extra zdieľať, a tak ju dostať k viacerým novým uchádzačom!`,
  },
  removeDraftModal: {
    title: 'Vymazať ponuku?',
    description:
      'Potvrdením tlačidla sa vaša rozpracovaná ponuka vymaže. Tento krok sa už nebude dať vrátiť späť.',
    submit: 'Áno, vymazať',
    decline: 'Nie, nechcem vymazať',
  },
}
