import DefaultApp, { AppProps } from 'next/app'
import NextNprogress from 'nextjs-progressbar'
import { Provider } from 'react-redux'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { initDayjs } from 'utils/dayjs'
import withReduxStore from 'redux/withRedux'
import '../client/css/app.scss'
import AdminBar from 'components/topbar/AdminBar'
// import TopBar from 'components/topbar/TopBar'
// import BannerAd from 'components/topbar/BannerAdGreenfox'
import Header from 'components/Header'
import CookiesBanner from 'components/cookies/CookiesBanner'
import Footer from 'components/Footer'
import NotificationQueryBar from 'components/NotificationQueryBar'
import { isStoreMissingAdditionalData, isStoreMissingInitData } from 'redux/store'
import { userFullFetch } from 'redux/managers/user'
import { getAdminLogged, getCompanyLogged, getUserId, getUserLogged } from 'utils/helpers'
import RouterUtils from 'utils/router'
import { setPrevPageContext } from 'redux/managers/general'
import { getStats } from 'redux/managers/stats'
import SocialMeta from './_SocialMeta'
import AppHeader from './_appHeader'

initDayjs()

type PretlakAppProps = AppProps & {
  originalUrl: string
  store: any
  err: any
  isCompanyLogged: boolean
  isUserLogged: boolean
}

function PretlakApp({
  pageProps,
  originalUrl,
  store,
  Component,
  err,
  isCompanyLogged,
  isUserLogged,
}: PretlakAppProps) {
  const appType = pageProps?.pageConf?.app ?? 'standard'
  const context = pageProps?.pageConf?.context ?? ''
  const prevContext = usePrevious(context)

  useEffect(() => {
    const state = store.getState()

    if (isStoreMissingAdditionalData(state)) {
      getStats(store.dispatch)
    }
  }, [])

  useEffect(() => {
    store.dispatch(setPrevPageContext(prevContext || 'UNKNOWN'))
  }, [context])

  return (
    <Provider store={store}>
      <NextNprogress color="#ff431d" options={{ showSpinner: false }} />
      <AppHeader appType={appType} />
      <>
        <SocialMeta pageConf={pageProps.pageConf} originalUrl={originalUrl} />
        <AdminBar />
        {/*<TopBar />*/}
        {/* <BannerAd /> */}
        <Header
          appType={appType}
          context={context}
          isCompanyLogged={isCompanyLogged}
          isUserLogged={isUserLogged}
        />
        <NotificationQueryBar
          type={pageProps?.query?.notification}
          id={pageProps?.query?.notificationId}
        />
        <Component {...pageProps} err={err} />
        <CookiesBanner />
      </>
      <Footer appType={appType} isCompanyLogged={isCompanyLogged} />
    </Provider>
  )
}

PretlakApp.getInitialProps = async (context) => {
  const { ctx } = context
  const appProps = await DefaultApp.getInitialProps(context)
  const { originalUrl, query } = ctx.req || ''
  const { pageConf, notFound } = appProps.pageProps
  let state = ctx.store.getState()

  if (notFound) {
    if (ctx.res) {
      ctx.res.writeHead(302, { Location: '/404' })
      ctx.res.end()
    } else {
      RouterUtils.push('/404')
    }
  }

  const userId = getUserId(ctx)

  if (userId && isStoreMissingInitData(state)) {
    await userFullFetch(ctx.store.dispatch, userId)
  }

  state = ctx.store.getState()
  const isCompanyLogged = getCompanyLogged(state.user)
  const isUserLogged = getUserLogged(state.user)
  const isAdminLogged = getAdminLogged(state.user)

  if (pageConf && pageConf.requiresAdminLogin && !isAdminLogged) {
    if (ctx.res) {
      ctx.res.writeHead(302, { Location: '/' })
      ctx.res.end()
    } else {
      RouterUtils.push('/')
    }
  }

  if (pageConf && pageConf.requiresCompanyLogin && !isCompanyLogged) {
    const allowedNextUrls = ['/profile', '/job', '/payment']

    if (ctx.res) {
      const originalUrl = ctx.res.req.originalUrl

      if (allowedNextUrls.some((url) => originalUrl.includes(url))) {
        ctx.res.writeHead(302, {
          Location: `/login?next=${encodeURIComponent(originalUrl)}`,
        })
      } else {
        ctx.res.writeHead(302, { Location: '/login' })
      }
      ctx.res.end()
    } else {
      if (allowedNextUrls.some((url) => ctx.asPath.includes(url))) {
        RouterUtils.push(`/login?next=${encodeURIComponent(ctx.asPath)}`)
      } else {
        RouterUtils.push('/login')
      }
    }
  }

  if (pageConf && pageConf.requiresUserLogin && !isUserLogged) {
    const allowedNextUrls = ['/freelancers/profile']

    if (ctx.res) {
      if (allowedNextUrls.some((url) => originalUrl.includes(url))) {
        ctx.res.writeHead(302, {
          Location: `/freelancers/login?next=${encodeURIComponent(originalUrl)}`,
        })
      } else {
        ctx.res.writeHead(302, { Location: '/freelancers/login' })
      }
      ctx.res.end()
    } else {
      if (allowedNextUrls.some((url) => ctx.asPath.includes(url))) {
        RouterUtils.push(`/freelancers/login?next=${encodeURIComponent(ctx.asPath)}`)
      } else {
        RouterUtils.push('/freelancers/login')
      }
    }
  }

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      query: {
        ...query,
        ...appProps.pageProps.query,
        ...ctx.query,
      },
    },

    isCompanyLogged,
    isUserLogged,
    originalUrl,
  }
}

export default withReduxStore(PretlakApp)
