module.exports = {
  search: 'Search',
  title: 'Filter',
  hide: 'Hide filter',
  show: 'Show filter',
  reset: 'Reset',
  resetFull: 'Reset filter',
  skillLevel: 'Level',
  tags: 'Skill',
  tagsOther: 'Other',
  cooperationForm: 'Contract type',
  others: 'Others',
  newsletter: 'Subscribe for jobs',
  paymentType: {
    title: 'Payment type',
    type: {
      tpp: 'Employment Contract',
      invoice: 'Invoice',
    },
  },
  salaryFilterTitle: 'Minimum: {{salaryValue}} / month',
  key: {
    jobCategories: 'Category',
    jobTypes: 'Form',
    skillLevels: 'Seniority',
    cities: 'City',
    countries: 'Country',
    tags: 'Skill',
    paymentTypes: 'Type',
    salary: 'Salary',
    others: 'Others',
  },
}
