module.exports = {
  search: 'Vyhledat',
  title: 'Filtr',
  hide: 'Skrýt filtr',
  show: 'Zobrazit filtr',
  reset: 'Reset',
  resetFull: 'Reset filtr',
  skillLevel: 'Úroveň',
  tags: 'Skill',
  tagsOther: 'Jiný',
  cooperationForm: 'Forma spolupráce',
  others: 'Ostatní',
  newsletter: 'Odběr nových nabídek',
  paymentType: {
    title: 'Způsob vyplácení',
    type: {
      tpp: 'Hlavní pracovní poměr',
      invoice: 'Faktura',
    },
  },
  salaryFilterTitle: 'Minimálně: {{salaryValue}} / měsíc',
  key: {
    jobCategories: 'Kategorie',
    jobTypes: 'Forma',
    skillLevels: 'Seniorita',
    cities: 'Město',
    countries: 'Země',
    tags: 'Skill',
    paymentTypes: 'Typ',
    salary: 'Plat',
    others: 'Ostatní',
  },
}
